<template>
  <div
    class="bg-primary-light border-none w-11/12 m-auto md:w-2/4 rounded-xl p-5 md:p-10"
  >
    <p class="text-center text-xl font-bold text-gray-50 mb-5">
      External authentication
    </p>
    <p class="text-center text-l text-gray-50 mb-2">
      Scan QR code with the dedicated application to be registered :
    </p>
    <div class="flex justify-center mb-5">
      <custom-loader
        v-if="loading"
        class="mr-2 mt-0.5"
        color="#028ef6"
        size="20px"
      >
      </custom-loader>
      <p
        class="text-center text-l"
        :class="
          status.indexOf('Error') != -1 ? 'text-red-500' : 'text-secondary'
        "
      >
        {{ status
        }}<span
          v-if="
            status.indexOf('Error') != -1 && status.indexOf('Account') != -1
          "
          class="text-sm underline"
          ><br /><!-- SSE not launching if using router-link with no refresh --><a
            href="/signin"
            >Since you are already registered, you can try to sign in by
            clicking here</a
          ></span
        >
      </p>
    </div>
    <qrcode-vue
      v-if="qrCodeData"
      :value="qrCodeData"
      :size="270"
      level="H"
      class="bg-white mx-auto mb-5 p-4"
    />
    <p
      v-if="verifiableCredentials.length > 0"
      class="text-center text-sm text-gray-400 mt-10 mb-1"
    >
      <i class="fas fa-shield-alt mr-1"></i>Verifiable credentials needed :
    </p>
    <p
      class="text-center text-sm text-gray-100"
      v-for="vc in verifiableCredentials"
      :key="vc"
    >
      {{ vc }}
    </p>
  </div>
</template>

<script>
import { ref, inject, onBeforeUnmount } from 'vue';
export default {
  async setup() {
    const $api = inject('$api');
    const $cookies = inject('$cookies');
    const qrCodeData = ref('');
    const shortenLinkMydid = ref('');
    const shortenLinkEchoslife = ref('');
    const status = ref('');
    const verifiableCredentials = ref({});
    const loading = ref(null);

    onBeforeUnmount(() => {
      $api.closeChallengeValidation();
    });

    const newChallenge = (await $api.createChallenge()).data;
    qrCodeData.value = JSON.stringify(newChallenge);
    verifiableCredentials.value = newChallenge.verifiableCredentials;

    // CREATE SSE CONNECTION
    $api.waitChallengeValidation(newChallenge.challenge, (response) => {
      switch (response.status) {
        case 'waiting':
          status.value = 'Waiting scan from mobile application';
          loading.value = true;
          break;
        case 'treating':
          status.value = 'Checking your registration';
          loading.value = true;
          break;
        case 'expired':
          status.value = 'Error while registering : ' + response.message;
          loading.value = false;
          break;
        case 'validated':
          status.value = "Congratulations, you're registered. Redirecting...";
          loading.value = false;
          setTimeout(
            () =>
              window.location.replace(
                `https://support.mydid.com/user/remote-login/1.html?token=${newChallenge.challenge}`
              ),
            1000
          );
          break;
      }
    });

    return {
      qrCodeData,
      shortenLinkMydid,
      shortenLinkEchoslife,
      status,
      verifiableCredentials,
      loading,
    };
  },
};
</script>
