import { createRouter, createWebHistory } from 'vue-router';
import VueCookies from 'vue-cookies';

import Auth from './views/Auth';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: Auth },
    { path: '/:notFound(.*)',  redirect: '/' }
  ]
});

// router.beforeEach((to, from, next) => {
//   if ((to.path == '/user' || to.path == '/admin') && !VueCookies.get('token')) {
//     return router.push('/');
//   }
//   if ((to.path != '/user' && to.path != '/admin') && VueCookies.get('token')) {
//     return router.push('/user');
//   }
//   return next();
// })

export default router;
 